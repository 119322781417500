import { Icon } from 'react-icons-kit'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'
import { eye } from 'react-icons-kit/feather/eye'
import { useEffect, useState } from 'react'
import { uploadFile } from '../services/apiService'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const UserForm = ({ data, sendToUpdate, responseMessage, errorMessage, setErrorMessage }) => {
	const [formData, setFormData] = useState({
		id: 0,
		name: "",
		role: "",
		pin: "",
		email: "",
		status: "active",
		image: null,
		lastVenue: 1,
		lastLogin: null,
		alternateId: null,
	})
	const [fieldsToSend, setFieldsToSend] = useState([])
	const [image, setImage] = useState(null)
	const [inputPin, setInputPin] = useState({ type: 'password', icon: eyeOff })
	const [inputPass, setInputPass] = useState({ type: 'password', icon: eyeOff })
	const [userImage, setUserImage] = useState(null)

	useEffect(() => {
		//console.log('data', data)
		if (data.id) {
			setFormData(data)
			setImage(data.image)
		}
	}, [data])

	useEffect(() => {
		if (userImage) {
			const file = userImage.files[0]
			if (file) {
				uploadAndSetImage(file)
			}
		}

		// eslint-disable-next-line
	}, [userImage])

	const uploadAndSetImage = async (file) => {
		try {
			const res = await uploadFile(file, 'users', 'image')
			console.log('res', res)
			if (res.result === 'ok') {
				setImage(res.data.fullPath)
				setFormData({
					...formData,
					image: res.data.fullPath,
				})
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	const handleToggle = (input, setInput) => {
		if (input.type === 'password') {
			setInput({ type: 'text', icon: eye })
		} else {
			setInput({ type: 'password', icon: eyeOff })
		}
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldsToSend(new Set([...fieldsToSend, name]))

		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSave = () => {
		//console.log('formData', formData)
		// reset error message
		setErrorMessage('')
		
		// check if all required fields are filled
		if (!formData.status || formData.status === '') {
			setErrorMessage('El campo Status es obligatorio')
			return
		}

		if (!formData.role || formData.role === '') {
			setErrorMessage('El campo Rol es obligatorio')
			return
		}

		const fieldsToSend = new Set(Object.keys(formData))

		if (fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
				.filter((key) => fieldsToSend.has(key))
				.reduce((obj, key) => {
					obj[key] = formData[key]
					return obj
				}, {})

			sendToUpdate(dataToSend)
		}
	}

	return (
		<form className="mt-4 px-4" autocomplete="off">
			<div className="d-sm-flex align-items-center justify-content-between mb-3">
				<div>
					<div className="form-group">
						<h6 className="mb-0 fs-4 mb-2">Id</h6>
						<input
							type="text"
							className="form-control"
							id="userId"
							placeholder="Id"
							value={formData.id}
							disabled
						/>
					</div>
				</div>

				{formData.image && (
					<div className="d-flex align-items-center justify-content-center mb-2 user-image">
						<div className="d-flex align-items-center justify-content-center rounded-circle">
							<div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
								<img src={image} alt="preview img" className="w-100 h-100" />
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Cambiar Imagen</h6>
				<input
					type="file"
					id="userImage"
					onChange={(e) => setUserImage(e.target)}
					name="image"
					className="form-control form-control-file"
				/>
			</div>

			<div className="form-group">
				<h6 className="mt-4 fs-4 mb-3">Estado</h6>
				<div className="form-check form-check-inline">
					<input
						className="form-check-input success"
						type="radio"
						id="activeStatus"
						checked={formData.status === 'active'}
						value="active"
						onChange={handleInputChange}
						name="status"
					/>
					<label className="form-check-label" htmlFor="activeStatus">
						Active
					</label>
				</div>
				<div className="form-check form-check-inline">
					<input
						className="form-check-input danger"
						type="radio"
						name="status"
						id="inactiveStatus"
						value="inactive"
						checked={formData.status === 'inactive'}
						onChange={handleInputChange}
					/>
					<label className="form-check-label" htmlFor="inactiveStatus">
						Inactive
					</label>
				</div>
			</div>

			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Nombre</h6>
				<input
					type="text"
					className="form-control"
					id="userName"
					name="name"
					value={formData.name}
					onChange={handleInputChange}
				/>
			</div>
			<div className="d-sm-flex align-items-center mb-3">
				<div className="form-group mt-4 col-6">
					<h6 className="mb-0 fs-4 mb-2">Email</h6>
					<input
						type="text"
						className="form-control"
						id="userEmail"
						name="email"
						value={formData.email}
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-group mt-4 col-6 ps-2">
					<h6 className="mb-0 fs-4 mb-2">Rol</h6>
					<select onChange={handleInputChange} className="form-control" name="role" value={formData.role}>
						<option value="">-</option>
						<option value="seller">Vendedor</option>
						<option value="waiter">Camarero (POS)</option>
						<option value="salesmanager">Jefe de ventas</option>
						<option value="host">Host</option>
						<option value="waitermanager">Jefe de sala (POS)</option>
						<option value="admin">Admin</option>
						<option value="eventmanager">Event manager</option>
						<option value="mice">MICE</option>
						<option value="cashier">Caja</option>
					</select>
				</div>
			</div>
			<div className="d-sm-flex align-items-center mb-3">
				<div className="form-group mt-4 col-6">
					<h6 className="mb-0 fs-4 mb-2">Último Login</h6>
					<input
						type="text"
						className="form-control"
						id="userLastLogin"
						defaultValue={moment(formData.lastLogin).format('DD-MM-YYYY HH:mm:ss')}
						disabled
					/>
				</div>
				<div className="form-group mt-4 col-6 ps-2">
					<h6 className="mb-0 fs-4 mb-2">Último Tablao</h6>
					<input
						type="text"
						className="form-control"
						id="userLastVenue"
						defaultValue={formData.lastVenue ?? 1}
						disabled
					/>
				</div>
			</div>

			<div className="d-sm-flex align-items-center mb-3">
				<div className="form-group mt-4 col-6">
					<h6 className="mb-0 fs-4 mb-2">Pin</h6>
					<div className="d-sm-flex align-items-center mb-3">
						<input
							type={inputPin.type}
							className="form-control"
							id="userPin"
							value={formData.pin}
							name="pin"
							onChange={handleInputChange}
						/>
						<span
							className="flex justify-around items-center mx-2 icon-eye"
							onClick={() => handleToggle(inputPin, setInputPin)}
						>
							<Icon className="absolute mr-10" icon={inputPin.icon} size={18} />
						</span>
					</div>
				</div>

				<div className="form-group mt-4 col-6">
					<h6 className="mb-0 fs-4 mb-2">Clave</h6>
					<div className="d-sm-flex align-items-center mb-3">
						<input
							type={inputPass.type}
							className="form-control"
							id="userPassword"
							value={formData.password}
							name="password"
							onChange={handleInputChange}
						/>
						<span
							className="flex justify-around items-center mx-2 icon-eye"
							onClick={() => handleToggle(inputPass, setInputPass)}
						>
							<Icon className="absolute mr-10" icon={inputPass.icon} size={18} />
						</span>
					</div>
				</div>
			</div>

			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Token</h6>
				<textarea type="text" className="form-control" id="userToken" defaultValue={formData.token} disabled />
			</div>
			<div className="d-flex align-items-right fs-2 mt-4">
				Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
			</div>
			<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
				Guardar
			</button>

			{responseMessage && (
				<div className="mt-4 alert alert-success" role="alert">
					{responseMessage}
				</div>
			)}

			{errorMessage  && errorMessage !== '' && (
				<div className="mt-4 alert alert-danger" role="alert">
					{errorMessage}
				</div>
			)}
		</form>
	)
}

export default UserForm
