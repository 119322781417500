import {useState, useEffect, useRef} from 'react'
import InputSelect from '../components/InputSelect/InputSelect'
import moment from 'moment'
import { shifts } from '../utils/bookings-data'
import { getOptions, getModelById, getDataApi } from '../services/apiService'

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

/**
 * search for -> [:lang]...[: if not -> [:fallBack]...[: if not -> str
 */
const _ = (str, lang, fallBack = 'en') => str.includes(`[:${fallBack}]`) ? (new RegExp(`\\[:${lang}\\]([^\\[]+)\\[:`, 'g').exec(str) || new RegExp(`\\[:${fallBack}\\]([^\\[]+)\\[:`, 'g').exec(str))[1] : str
// console.log(_('[:en]bla bla[:en]ble ble[:fr]bli bli[:]', 'es'))
// console.log(_('[:en]bla bla[:en]ble ble[:fr]bli bli[:]', 'en'))
// console.log(_('[:en]bla bla[:en]ble ble[:fr]bli bli[:]', 'fr'))
// console.log(_('[:en]bla bla[:en]ble ble[:fr]bli bli[:]', 'jp'))

const now = new Date()

const AsyncComponent = ({model, id, onClick, removeSelf}) => {

    const [value, setValue] = useState('')

    const updateValue = async () => {

        let res = await getModelById(0, model, id)
        if (res.result==='ok') {
            setValue(res.data.name)
        } else removeSelf()
    }

    useEffect(() => {updateValue()}, [])

    return ( <span onClick={onClick} className='rule_value'>{value}</span> )

}

const PriceRuleForm = ({data, sendToUpdate, responseMessage}) => {

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        venueId: 0,
        cumulative: 0,
        web: 1,
        promotionalText: '',
        rumba: 1,
        visible: 1,
        maxDaysBefore: 365,
        daysInAdvance: 0,
        maxHour: 23,
        createdAt: '',
        priority: 0,
        updatedAt: '',
        availableFrom: new Date(new Date(now.getFullYear(), now.getMonth(), 1).getTime() - now.getTimezoneOffset() * 60000).toISOString(),
        availableTo: new Date(new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() - now.getTimezoneOffset() * 60000).toISOString(),
        discount: 1,
        isSpecial: 0,
        journey: 'A',
        rules: {
            noDts: [],
            noEventIds: [],
            noWeekDays: [],
            yesShiftIds: [],
            yesProductIds: [],
            noOriginIds: []
        }
    })
    const [products, setProducts] = useState({
        options: [],
        tags: []
    })
    const [origins, setOrigins] = useState({
        options: [],
        tags: []
    })
    const noDt1 = useRef(null)
    const noDt2 = useRef(null)
    const changedFields = useRef(new Map())
    const rulesValues = useRef({
        noDts: [],
        noEventIds: [],
        noWeekDays: [],
        yesShiftIds: [],
        yesProductIds: [],
        noOriginIds: []
    })

    const handleInputChange = (e) => {
        let {target: {name, value}} = e
        setFormData((prev) => ({...prev, [name]: value}))
        changedFields.current.set(name, '')
    }

    const handleSave = (duplicate = false) => {

        let omitKeys = ['id', 'createdAt', 'updatedAt']
        let copy = JSON.parse(JSON.stringify(formData))
        let dataToSend = {}
        Object.keys(copy).forEach(key => {

            if (!omitKeys.includes(key) && (changedFields.current.has(key) || formData.id===0 || key==='venueId' || duplicate)) {

                if (['web', 'rumba', 'discount', 'maxHour', 'daysInAdvance', 'cumulative', 'venueId', 'priority', 'maxDaysBefore', 'isSpecial'].includes(key))
                    dataToSend[key] = key!=='discount' ? Math.floor(Number(copy[key])) : Number(copy[key])
                else if (key==='availableFrom')
                    dataToSend[key] = copy[key].substring(0, 10) + 'T00:00-00:00'
                else if (key==='availableTo')
                    dataToSend[key] = copy[key].substring(0, 10) + 'T23:59-00:00'
                else if (key==='rules') 
                    dataToSend[key] = Object.keys(copy[key]).reduce((acc, rule) => {
                        if (copy[key][rule].length > 0) acc[rule] = copy[key][rule]
                        return acc
                    }, {})
                else
                    dataToSend[key] = copy[key]
            }
        })

        sendToUpdate(dataToSend, duplicate)        
    }

    
    const handleDelete = async () => {
        if (window.confirm('¿Estás seguro de que quieres borrar esta regla de precio?')){
            if (formData.id>0){
                await sendToUpdate({id: formData.id, venueId:1 ,forceDelete: true})
            }
            window.location.href = '/price-rules'
        }
    }

    const handleRemove = (e, value, key) => {
        changedFields.current.set('rules', '')
        setFormData((prev) => {

            let newValues = [...prev.rules[key]]
            newValues.splice(newValues.indexOf(value), 1)

            return {
                ...prev,
                rules: {
                    ...prev.rules,
                    [key]: newValues
                }
            }
        })
    }

    const handleClear = (e, key) => {
        e.preventDefault()
        setFormData((prev) => ({...prev, rules: {...prev.rules, [key]: []}}))
        changedFields.current.set('rules', '')
    }

    const handleRuleValues = (e) => {
        let {target: {name, value}} = e

        if (name.includes('noDts')) {
            let dt1 = noDt1.current.value
            let dt2 = noDt2.current.value
            rulesValues.current.noDts = 
                dt1 === dt2 && dt1 ? [dt1] :
                dt1 || dt2 ? [[dt1, dt2].filter(d => d !== '').join('...')] : []
        } else
            rulesValues.current[name] = Array.isArray(value) ? value : [value]
    }

    const handleAddNew = (e, key) => {
        e.preventDefault()
        changedFields.current.set('rules', '')

        let value = JSON.parse(JSON.stringify(rulesValues.current[key]))
        if (key==='noDts' && value.length && value[0].includes('...')) {
            //sort dates
            value = [value[0].split('...').sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).join('...')]
        }

        setFormData((prev) => {

            return {
                ...prev,
                rules: {
                    ...prev.rules,
                    [key]: Array.from(new Set([...prev.rules[key], ...value]))
                }
            }
        })
    }

    const getProducts = async () => {

        const products = await getOptions('productId')
        const tags = {}
        const options = []

        products.productId.forEach(p => {

            if (p.venueId===3 || p.group===p.name) return
            options.push({value: p.id, name: `${p.name}-${p.venueId===1 ? 'Cardamomo' : '1911'}`})

            if (typeof p.zone === 'string' && p.zone !== '') {
                if ('tickets' in tags) {
                    tags.tickets.values.push(Number(p.id))
                } else {
                    tags.tickets = {
                        name: 'Tickets',
                        values: [p.id],
                        icon: '',
                        active: false,
                    }
                }
            } else if (typeof p.group === 'string' && p.group !== '') {
                if (p.group in tags) {
                    tags[p.group].values.push(Number(p.id))
                } else {
                    tags[p.group] = {
                        name: p.group,
                        values: [p.id],
                        icon: '',
                        active: false,
                    }
                }
            }
        })
        setProducts({
            options: options,
            tags: Object.values(tags)
        })
    }
    const getOrigins = async () => {
        const result = await getDataApi(0, 'origin', 0, 3000, null, null, 'id', 'asc')
        const origins = Object.values(result.data).map((origin) => {
            return {
                id: origin.id,
                name: origin.name,
                parent: origin.parent,
                order: origin.order,
                venueId: origin.venueId,
            }
        })
        let tags = {}
        let options = []
        const parents = origins.filter((o) => o.parent === 0)
        origins.forEach((origin) => {
            options.push({ value: origin.id, name: origin.name || origin.id.toString() })
            let parent = parents.find((p) => p.id === origin.parent)
            if (parent) {
                const { name } = parent
                tags[name] = tags[name] || { name: name, values: [], icon: '', active: false }
                tags[name].values = [...tags[name].values, origin.id]
            }
        })
        setOrigins({
            options: options,
            tags: Object.values(tags)
        })
    }

    useEffect(() => {
        if (data.id > 0) setFormData((prev) => ({...data, rules: { ...prev.rules, ...data.rules}}))
    }, [data])

    useEffect(() => {
        getProducts()
        getOrigins()
    }, [])

    return (

        <form className='pricing_rule_form'>
            <div>
                <div className='left_side'>
                    <div className="input_group">
                        <div>
							<p className="label">Id</p>
							<input type="text" className="form-control" id="price-rule-id" placeholder="Id" value={formData.id} disabled onChange={()=>{/* prevents the error trigger */}} />
						</div>
						<div>
							<p className="label">Nombre</p>
							<input type="text" className="form-control" id="price-rule-name" name="name" defaultValue={formData.name} onChange={handleInputChange} />
						</div>
                        <div>
							<p className="label">Journey</p>
							<select name='journey' id="price-rule-journey" value={formData.journey} onChange={handleInputChange}>
								<option value={'-'}>-</option>
								<option value={'A'}>A</option>
								<option value={'B'}>B</option>
							</select>
						</div>
                    </div>
                    <div className='input_group'>
                        <div>
                            <p className='label'>Descripción</p>
                            <textarea id="price-rule-description" className='form-control' name='description' value={formData.description} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className='input_group'>
                        <div>
                            <p className='label'>Texto promocional</p>
                            <p>Traducciones:<br/>Texto para todos los idomas: texto<br />Texto traducido: {'[:es]texto[:en]text[:]'}</p>
                            <textarea 
                                id="price-rule-promotionalText" 
                                className='form-control' 
                                name='promotionalText' 
                                value={formData.promotionalText} 
                                onChange={(e) => {
                                    const regex = /^(?:\[:[a-zA-Z]{2}\][^[]+)+(\[:\])$/
                                    let {value} = e.target
                                    if (value==='' || value.indexOf('[:') < 0|| regex.test(value)) {
                                        e.target.style.border = '1px solid #dfe5ef'
                                    } else  {
                                        e.target.style.border = '1px solid red'
                                    }
                                    handleInputChange(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="input_group">
                        <div className='discount_container'>
                                <p className="label">{'Modificación de precio'}</p>
                                <p >
                                    {'· valor > 0 && valor < 1. EJEMPLO: 0.25 = -25% al precio del producto'}<br />
                                    {'· valor > -1 && valor < 0. EJEMPLO: -0.25 = +25% al precio del producto'}<br />
                                    {'· valor >= 1. EJEMPLO: 3 = -3€ al precio del producto'}<br />
                                    {'· valor <= -1. EJEMPLO: -3 = +3€ al precio del producto'}
                                </p>
                                <input 
                                    type="number" 
                                    id="price-rule-discount"
                                    className="form-control" 
                                    name="discount" 
                                    value={formData.discount} 
                                    step={0.05}
                                    onChange={(e) => {
                                        if (!isNaN(parseInt(e.target.value), 10)) handleInputChange(e)
                                    }} 
                                />							
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Tablao</p>
							<select name='venueId' id="price-rule-venueId" value={formData.venueId} onChange={handleInputChange}>
								<option value={0}>Todos</option>
								<option value={1}>cardamomo</option>
								<option value={2}>Villarosa 1911</option>
							</select>
						</div>
                        <div>
							<p className="label">Acumulable</p>
                            <select name='cumulative' id="price-rule-cumulative" value={formData.cumulative} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Web</p>
                            <select name='web' id="price-rule-web" value={formData.web} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                        <div>
							<p className="label">Rumba</p>
                            <select name='rumba' id="price-rule-rumba" value={formData.rumba} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                        <div>
							<p className="label">Visible</p>
                            <select name='visible' id="price-rule-visible" value={formData.visible} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Disponibilidad</p>
                            <p>Fecha de creación desde</p>
							<input
								type='date'
                                id="price-rule-availableFrom"
                                name='availableFrom'
								className="form-control" 
								value={formData.availableFrom.substring(0, 10)}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
                            <p className="label"><br /></p>
                            <p>Fecha de creación hasta</p>
							<input
								type='date'
                                name='availableTo'
                                id="price-rule-availableTo"
								className="form-control" 
								value={formData.availableTo.substring(0, 10)}
								onChange={handleInputChange}
							/>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Antelación desde</p>
                            <p>Días previos al evento</p>
                            <input
								type='number'
                                name='maxDaysBefore'
                                id="price-rule-daysInAdvance"
                                min={0}
								className="form-control" 
								value={formData.maxDaysBefore}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
							<p className="label">Antelación hasta</p>
                            <p>Días previos al evento</p>
							<input
								type='number'
                                name='daysInAdvance'
                                id="price-rule-daysInAdvance"
                                min={0}
								className="form-control" 
								value={formData.daysInAdvance}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
                            <p className="label">Hora hasta</p>
                            <p>Ventas hasta esa hora</p>
							<input
								type='number'
                                min={0}
                                max={23}
                                name='maxHour'
                                id="price-rule-maxHour"
								className="form-control" 
								value={formData.maxHour}
								onChange={handleInputChange}
							/>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
                            <p className="label">Prioridad</p>
                            <p>
                                {'El número más bajo tiene mayor prioridad'}
                            </p>
							<input
								type='number'
                                name='priority'
                                id="price-rule-priority"
								className="form-control" 
								value={formData.priority}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
							<p className="label">Journey</p>
                            <p>
                                {'Las reglas de journey B sólo se ven en visitas de journey B'}
                            </p>
							<select name='journey' id="price-rule-journey" value={formData.journey} onChange={handleInputChange}>
								<option value={'-'}>-</option>
								<option value={'A'}>A</option>
								<option value={'B'}>B</option>
							</select>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
                            <p className="label">Evento especial</p>
                            <p>Si es especial, en el motor de reservas aparecerá el estilo de evento especial y se mostrará el texto promocional.
                                para evitar posibles conflictos con otras reglas, esta deberá tener:<br />
                                · prioridad más alta (-10)<br />
                                · discount 0 <br />
                                · aplicable a ningun producto <br />
                                · rumba no <br />
                                · visible no
                            </p>
                            <select name='isSpecial' id="price-rule-cumulative" value={formData.isSpecial} onChange={handleInputChange}>
                                <option value={0}>No</option>
                                <option value={1}>Sí</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div className='right_side'>
                <div>
                        <p className='label'>Aplicable a los siguientes productos</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.yesProductIds.map((v, i) => {

                                    return (
                                        <span onClick={(e) => handleRemove(e, v, 'yesProductIds')} key={i} name={`yesProductIds-${v}`} className='rule_value'>{
                                            products.options.length ? products.options.find(p => p.value===v)?.name : ''
                                        }</span>
                                    )
                               })
                            }
                        </div>
                        <div className='rule_controls'>
                        <InputSelect 
                                searchable={true}
                                selectId='slct-yesProductIds'
                                name='yesProductIds'
                                toggleSelects={true}
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Aplicado a'
                                selectAllOption={true}
                                onInput={handleRuleValues}
                                tags={products.tags}
                                options={products.options}
                            />
                            <button 
                                className='add' 
                                name='yesProductIds'
                                onClick={(e) => handleAddNew(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR fechas</p>
                        <p>Es posible excluir una fecha en concreto o un rango de fechas.</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noDts.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'noDts')} key={i} name={`noDts-value`} className='rule_value'>{
                                        v.replace(/\.\.\./g, ' - ')
                                    }</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                            <input
								type='date'
                                name='noDts-0'
                                ref={noDt1}
                                id="price-rule-noDts-from"
								className="form-control" 
								onChange={handleRuleValues}
							/>
                            <input
								type='date'
                                ref={noDt2}
                                name='noDts-1'
                                id="price-rule-noDts-to"
								className="form-control" 
								onChange={handleRuleValues}
							/>
                            <button 
                                className='add' 
                                name='noDts'
                                onClick={(e) => handleAddNew(e, 'noDts')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='noDts'
                                onClick={(e) => handleClear(e, 'noDts')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR eventos</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noEventIds.map((v, i) => 
                                    <AsyncComponent key={i} id={v} model={'event'} onClick={(e) => handleRemove(e, v, 'noEventIds')} removeSelf={() => handleRemove(false, v, 'noEventIds')}/>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
							<input type="text" id="price-rule-noEventIds" className="form-control" name="noEventIds" placeholder='EventId' onChange={handleRuleValues} />
                            <button 
                                className='add' 
                                name='noEventIds'
                                onClick={(e) => handleAddNew(e, 'noEventIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='noEventIds'
                                onClick={(e) => handleClear(e, 'noEventIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR días</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noWeekDays.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'noWeekDays')} key={i} name={`noWeekDays-${v}`} className='rule_value'>{days[Number(v)]}</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                            <InputSelect 
                                selectId='slct-noWeekDays'
                                toggleSelects={true}
                                name='noWeekDays'
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Días excluidos'
                                onInput={handleRuleValues}
                                tags={[
                                    {
                                        name: 'Fines',
                                        values: [6, 0],
                                        icon: '',
                                        active: false,
                                    },
                                    {
                                        name: 'Entre',
                                        values: [1, 2, 3, 4, 5],
                                        icon: '',
                                        active: false,
                                    },
                                ]}
                                options={days.map((d, i) => ({value: i, name: d}))}
                            />
                            <button 
                                className='add' 
                                name='noWeekDays'
                                onClick={(e) => handleAddNew(e, 'noWeekDays')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='noWeekDays'
                                onClick={(e) => handleClear(e, 'noWeekDays')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>INCLUIR pases</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.yesShiftIds.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'yesShiftIds')} key={i} name={`yesShiftIds-${v}`} className='rule_value'>{
                                        shifts.find(s => s.value===v).name
                                    }</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                        <InputSelect 
                                selectId='slct-yesShiftIds'
                                name='yesShiftIds'
                                toggleSelects={true}
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Pases incluidos'
                                selectAllOption={true}
                                onInput={handleRuleValues}
                                tags={[
                                    {
                                        name: 'Cardamomo',
                                        values: shifts.filter((s) => s.dependencies.venueId===1).map(s => s.value),
                                        icon: '',
                                        active: false,
                                    },
                                    {
                                        name: '1911',
                                        values: shifts.filter((s) => s.dependencies.venueId===2).map(s => s.value),
                                        icon: '',
                                        active: false,
                                    },
                                ]}
                                options={shifts}
                            />
                            <button 
                                className='add' 
                                name='yesShiftIds'
                                onClick={(e) => handleAddNew(e, 'yesShiftIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesShiftIds'
                                onClick={(e) => handleClear(e, 'yesShiftIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR puntos de venta</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noOriginIds.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'noOriginIds')} key={i} name={`noOriginIds-${v}`} className='rule_value'>{
                                        origins.options.find(s => s.value===v).name
                                    }</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                        <InputSelect 
                                selectId='slct-noOriginIds'
                                name='noOriginIds'
                                toggleSelects={true}
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Origenes excluidos'
                                onInput={handleRuleValues}
                                tags={origins.tags}
                                searchable={true}
                                options={origins.options}
                            />
                            <button 
                                className='add' 
                                name='noOriginIds'
                                onClick={(e) => handleAddNew(e, 'noOriginIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='noOriginIds'
                                onClick={(e) => handleClear(e, 'noOriginIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>


           {    formData.id>0 && ( 
                <div className='update_info'>
				    Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
                </div>
            )}
            <button type="button" className="btn btn-danger mt-3" style={{'float':'right'}} onClick={handleDelete}>
                {formData.id===0 ? 'Cancelar' : 'Borrar'}
            </button>
            
            <button type="button" className="btn rmb-btn-primary mt-3" onClick={() => handleSave(false)}>
                {formData.id===0 ? 'Crear' : 'Guardar'}
            </button>
            {formData.id!==0 && (
                <button type="button" className="btn rmb-btn-secondary mt-3 ms-3" onClick={() => handleSave(true)}>
                    Duplicar
                </button>
            )}
            {responseMessage && (
                <div className="mt-4 alert alert-success" role="alert">
                    {responseMessage}
                </div>
            )}
        </form>

    )
}

export default PriceRuleForm