import useApp from "../utils/useApp"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

const MenuLink = ({href = '/', text = '', icon = '', roles = [], noRoles = [], ...rest}) => {

	const {state: { user }} = useApp()
	const location = useLocation()
	if (noRoles.includes(user.role) || (!roles.includes(user.role) && roles.length > 0)) return null
	
	return (
		<li className={`sidebar-item ${location.pathname === href ? 'selected' : ''}`} {...rest}>
			<Link className="sidebar-link" to={href} aria-expanded="false">
				<span>
					<i className={`ti ${icon}`}></i>
				</span>
				<span className="hide-menu">{text}</span>
			</Link>
		</li>
	)
}
const MenuSeparator = ({text = '', icon = '', roles = [], noRoles = []}) => {

	const {state: { user }} = useApp()
	if (noRoles.includes(user.role) || (!roles.includes(user.role) && roles.length > 0)) return null

	return (
		<li className="nav-small-cap">
			<i className={`ti ${icon} nav-small-cap-icon fs-4`}></i>
			<span className="hide-menu">{text}</span>
		</li>
	)
}

const AsideComponent = () => {

	const {state: { user }} = useApp()

	return (
		<aside className="left-sidebar">
			{
				//  Sidebar scroll
			}
			<div>
				<div className="brand-logo d-flex align-items-center justify-content-between">
					<a href="./index.html" className="text-nowrap logo-img">
						<img src="/images/rumba.png" className="dark-logo" width="38" height="38" alt="Logo" />
						<img
							src="/images/rumba.svg"
							className="dark-logo"
							width="60"
							alt="Rumba"
							style={{ marginLeft: 12 }}
						/>
					</a>
					<div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
						<i className="ti ti-x fs-8"></i>
					</div>
				</div>
				{
					//  Sidebar navigation
				}
				<nav className="sidebar-nav scroll-sidebar" data-simplebar>
					<ul id="sidebarnav">
						{/* Reservas */}
						<MenuSeparator text="Reservas" icon="ti-dots" roles={['admin','eventmanager']}/>
						<MenuLink href="/" text="inicio" icon="ti-home" roles={['admin']} />
						<MenuLink href="/sales" text="Ventas" icon="ti-ticket" roles={['admin', 'eventmanager']} />
						<MenuLink href="/bookings-lines" text="Líneas de producto" icon="ti-notes" roles={['admin']} />
						<MenuLink href="/events" text="Eventos" icon="ti-calendar" roles={['admin', 'eventmanager']} />
						<MenuLink href="/status" text="Estado tablao" icon="ti-theater" roles={['admin']} />
						{/* Facturación */}
						<MenuSeparator text="Facturación" icon="ti-dots" roles={['admin']}/>
						<MenuLink href="/payments" text="Pagos" icon="ti-coin-euro" roles={['admin']} />
						<MenuLink href="/comisions" text="Comisiones" icon="ti-discount" roles={['admin']} />
						{/*  Configuración */}
						<MenuSeparator text="Configuración" icon="ti-dots" noRoles={['eventmanager']}/>
						<MenuLink href="/cashiers" text="Caja" icon="ti-building-bank" roles={['admin', 'cashier']} />
						<MenuLink href="/products" text="Inventario" icon="ti-packages" noRoles={['eventmanager']}/>
						<MenuLink href="/users" text="Usuarios" icon="ti-users" roles={['admin']} />
						<MenuLink href="/shifts" text="Pases" icon="ti-clock-play" roles={['admin']} />
						<MenuLink href="/tables" text="Mesas" icon="ti-armchair" roles={['admin']} />
						<MenuLink href="/devices" text="Dispositivos" icon="ti-device-ipad" roles={['admin']} />
						<MenuLink href="/origins" text="Orígenes" icon="ti-radar" roles={['admin']} />
						<MenuLink href="/venues" text="Tablaos" icon="ti-pin" roles={['admin']} />
						<MenuLink href="/venues" text="Tablaos" icon="ti-pin" roles={['admin']} />
						<MenuLink href="/logs" text="Logs" icon="ti-database" roles={['admin']} />
						<MenuLink href="/trackings" text="Tracking" icon="ti-chart-infographic" roles={['admin']} />
						<MenuLink href="/price-rules" text="Pricing rules" icon="ti-coin" roles={['admin']} style={{marginBottom: '40px'}}/>
					</ul>
					<div className=""></div>
				</nav>
				<div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
					<div className="hstack gap-3">
						<div className="john-img">
							<img
								src="/images/profile/user-1.jpg"
								className="rounded-circle"
								width="40"
								height="40"
								alt=""
							/>
						</div>
						<div className="john-title">
							<h6 className="mb-0 fs-4 fw-semibold">Mathew</h6>
							<span className="fs-2 text-dark">Designer</span>
						</div>
						<button
							className="border-0 bg-transparent text-primary ms-auto"
							tabIndex="0"
							type="button"
							aria-label="logout"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							data-bs-title="logout"
						>
							<i className="ti ti-power fs-6"></i>
						</button>
					</div>
				</div>
				{
					//  End Sidebar navigation
				}
			</div>
			{
				//  End Sidebar scroll
			}
		</aside>
	)
}

export default AsideComponent
