import { useNavigate, useParams } from "react-router-dom"
import useApp from "../utils/useApp"
import NoPage from "./NoPage"

const PageWrapper = ({roles = [], noRoles = [], condition = () => true, Page = () => null}) => {

    const {state: { user }} = useApp()
    const navigate = useNavigate()
    const params = useParams()

    return (
        !condition(params) || 
        (roles.length > 0 && !roles.includes(user.role)) ||
        noRoles.includes(user.role)
    ) ? NoPage : Page
}

export default PageWrapper